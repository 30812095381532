export default {
  apiKey: 'AIzaSyBxEDlF2O2k7by30Zql6Cfg7F60Qv2LTlA',
  authDomain: 'radiant-labs-pipeline-v5.firebaseapp.com',
  projectId: 'radiant-labs-pipeline-v5',
  databaseURL: "https://radiant-labs-pipeline-v5-default-rtdb.firebaseio.com/",
  storageBucket: 'radiant-labs-pipeline-v5.appspot.com',
  messagingSenderId: '1084865365365',
  appId: '1:1084865365365:web:5d76fe800c133643e47f3c',
  measurementId: 'G-ZWGPH7DSZT',
};
