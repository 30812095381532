import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    primary: {
      main: '#EF6A20',
    },
    background: {
      default: '#F5F5F8',
    },
    text: {
      secondary: '#99999C',
    },
  },
});
