import { docData } from 'rxfire/firestore';
import firebase from 'firebase/compat/app';
import * as React from 'react';

export enum QueryStatus {
  idle,
  loading,
  success,
  error,
}

export interface ReducerAction<IDoc> {
  type: QueryStatus;
  payload?: IDoc | null;
  error?: string;
}

export interface QueryState<IDoc> {
  status: QueryStatus;
  data?: IDoc | null;
  error?: string;
}

const reducer = <IDoc>(
  _state: QueryState<IDoc>,
  action: ReducerAction<IDoc>
) => {
  switch (action.type) {
    case QueryStatus.idle:
      return { status: QueryStatus.idle, data: undefined, error: undefined };
    case QueryStatus.loading:
      return { status: QueryStatus.loading, data: undefined, error: undefined };
    case QueryStatus.success:
      return {
        status: QueryStatus.success,
        data: action.payload,
        error: undefined,
      };
    case QueryStatus.error:
      return {
        status: QueryStatus.error,
        data: undefined,
        error: action.error,
      };
    default:
      throw new Error('invalid action');
  }
};

export const useServerStatus = () => {
  const initialState = {
    status: QueryStatus.loading,
    data: undefined,
    error: undefined,
  };
  const [state, dispatch] = React.useReducer<
    React.Reducer<QueryState<string>, ReducerAction<string>>
  >(reducer, initialState);
  React.useEffect(() => {
    // const observable = docData<{ serverStatus: string }>(
    //   firebase.firestore().doc('appData/omniSciServer')
    // );
    // observable.subscribe((activity) => {
    //   dispatch({
    //     type: QueryStatus.success,
    //     payload: activity.serverStatus,
    //   });
    // });
    firebase.database().ref('/serverStatus').on('value', (snapshot)=> {
      dispatch({
            type: QueryStatus.success,
            payload: snapshot.val()
          });

    })
  }, []);

  return state;
};
