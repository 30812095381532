import { Auth0Provider } from '@auth0/auth0-react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import 'firebase/analytics';

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { SnackbarProvider } from 'notistack';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';
import App from './app/app';
import RemoteConfigWrapper from './app/providers/RemoteConfigWrapper';
import { theme } from './app/theme';
import { environment } from './environments/environment';

firebase.initializeApp(environment.firebaseConfig);

const db = firebase.database();
if (window.location.hostname === 'localhost') {
  // Point to the RTDB emulator running on localhost.
  console.log('Running in localhost');
  db.useEmulator('localhost', 9000);
}

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <Auth0Provider
        audience="https://api.analytics.radiantlabs.co"
        domain="auth.radiantlabs.co"
        clientId="4QwXp86ORzzUWaQkarftV3BMOqVCS3fN"
        redirectUri={window.location.origin}
      >
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <CssBaseline />
            <FirebaseAppProvider firebaseConfig={environment.firebaseConfig}>
              <RemoteConfigWrapper>
                <App />
              </RemoteConfigWrapper>
            </FirebaseAppProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </Auth0Provider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
