import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useActivityDetection } from '@radiant-labs/feature-activity-detector';
import { AddressSearchPage } from '@radiant-labs/feature-address-search';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useFirebaseApp, useRemoteConfigBoolean } from 'reactfire';
import { ReactComponent as Logo } from '../assets/RL Icon Light BG@1x.svg';
import { environment } from '../environments/environment';
import {
  DataManagerIcon,
  JupyterNotebookIcon,
  LogoutIcon,
  SqlIcon,
  TrendlineUpIcon,
} from './components/icons';
import NavButton from './components/NavButton';
import { QueryStatus, useServerStatus } from './hooks/useServerStatus';

const DRAWER_WIDTH = 80;

const queryClient = new QueryClient();
function App() {
  const instance = useFirebaseApp();
  const { data: searchFeatureToggle } = useRemoteConfigBoolean(
    'search_feature_toggle'
  );

  const { logout, user } = useAuth0();
  const location = useLocation();
  const theme = useTheme();
  const { data: serverStatus, status } = useServerStatus();

  const registerUserAsActive = React.useCallback(() => {
    const db = firebase.database();
    const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
    if (user) {
      const userStatusDatabaseRef = db.ref(
        '/users/' + user.email?.replace(regex, '') + '/connections'
      );

      db.ref('.info/connected').on('value', (snapshot) => {
        if (snapshot.val() === true) {
          const con = userStatusDatabaseRef.push();
          con.onDisconnect().remove();
          con.set(true);
        }
      });
    }
  }, [user]);

  const endUserSession = React.useCallback(() => {
    if (user) {
      logout({
        returnTo: window.location.origin,
      });
    }
  }, [user, logout]);

  useActivityDetection(
    registerUserAsActive,
    endUserSession,
    environment.activeTimeout
  );

  return (
    <Box display="flex">
      {/*
      <Drawer
        variant="permanent"
        style={{ width: DRAWER_WIDTH }}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.background.default,
            width: DRAWER_WIDTH,
          },
        }}
      >
        <Box py={2} px={2}>
          <Logo height={42} width={42} />
        </Box>
        <Box display="flex" flexGrow={1} />
        <Box my={3}>
          {searchFeatureToggle && (
            <NavButton
              icon={<SearchIcon />}
              label="Search"
              active={location.pathname.includes('search')}
              to="/search"
            />
          )}
          <NavButton
            icon={<TrendlineUpIcon />}
            label="Analytics"
            active={location.pathname.includes('dashboards')}
            to="/dashboards"
          />
          <NavButton
            icon={<SqlIcon />}
            label="SQL Editor"
            active={location.pathname.includes('sql-editor')}
            to="/sql-editor"
          />
          <NavButton
            icon={<DataManagerIcon />}
            label="Data Mananger"
            active={location.pathname.includes('data-manager')}
            to="/data-manager"
          />
          <Box display="flex" flexDirection="column" alignItems="center" my={2}>
            <IconButton
              component="a"
              target="_blank"
              href="https://gpu.radiantlabs.co/jupyter/hub/login?newnotebook=true"
              size="small"
            >
              <JupyterNotebookIcon />
            </IconButton>
            <Typography
              variant="button"
              style={{ fontSize: 9, fontWeight: 600, textAlign: 'center' }}
              color="textSecondary"
            >
              Jupyter Notebook
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexGrow={3} />
        <Box py={2} px={2} display="flex" flexDirection="column">
          <IconButton
            onClick={() => {
              endUserSession();
            }}
          >
            <LogoutIcon />
          </IconButton>
        </Box>
      </Drawer>
      */}

      <Switch>
        {/*
        {searchFeatureToggle && (
          <Route exact path="/search">
            <QueryClientProvider client={queryClient}>
              <AddressSearchPage />
            </QueryClientProvider>
          </Route>
        )}
         */}
        <Route
          path="/(dashboards|sql-editor|data-manager)"
          render={({ match: { params } }) => {
            if (status === QueryStatus.loading) return null;
            if (serverStatus !== 'ACTIVE')
              return (
                <Box
                  display="flex"
                  flex={1}
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  pt={10}
                >
                  <Typography>GPU server is starting...</Typography>
                  <CircularProgress />
                </Box>
              );
            return (
              <iframe
                id="gpu-iframe"
                src={`https://gpu.radiantlabs.co/omnisci/${
                  (params as string[])[0]
                }`}
                title="Radiant Labs Analytics"
                style={{
                  flexGrow: 1,
                  height: '100vh',
                  border: 0,
                }}
              ></iframe>
            );
          }}
        ></Route>

        <Route>
          <Redirect to="/dashboards" />
        </Route>
      </Switch>
    </Box>
  );
}

export default withAuthenticationRequired(App);
