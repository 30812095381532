import { CircularProgress } from '@material-ui/core';
import 'firebase/analytics';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';
import { ReactNode } from 'react';
import { RemoteConfigProvider, useInitRemoteConfig } from 'reactfire';

export const RemoteConfigWrapper = ({ children }: { children: ReactNode }) => {
  const { status, data: remoteConfigInstance } = useInitRemoteConfig(
    async (firebaseApp) => {
      const remoteConfig = getRemoteConfig(firebaseApp);
      remoteConfig.settings = {
        minimumFetchIntervalMillis: 10000,
        fetchTimeoutMillis: 10000,
      };
      await fetchAndActivate(remoteConfig);
      return remoteConfig;
    }
  );

  if (status === 'loading') return <CircularProgress />;

  return (
    <RemoteConfigProvider sdk={remoteConfigInstance}>
      {children}
    </RemoteConfigProvider>
  );
};

export default RemoteConfigWrapper;
