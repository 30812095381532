import { Button, CircularProgress } from '@material-ui/core';
import { time } from 'console';
import { SnackbarKey, useSnackbar } from 'notistack';
import * as React from 'react';

const ACTIVITY_EVENTS = [
  'mousedown',
  'mousemove',
  'keypress',
  'scroll',
  'touchstart',
  'click',
  'keydown',
] as const;

/**
 * Hook that handles detecting whether the current use is still active
 * @param sessionStartCallback - Function called when the user frist starts the application
 * @param sessionEndCallback  - Function called when the user has been determined inactive
 * @param activeTimeout - Number of seconds of inactivity before the inactiving warning snackbar is shown
 */
export const useActivityDetection = (
  sessionStartCallback: () => void,
  sessionEndCallback: () => void,
  activeTimeout = 10
) => {
  const [snackbarKey, setSnackbarKey] = React.useState<SnackbarKey>('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [timeInactive, setTimeInactive] = React.useState(0);

  useInterval(() => setTimeInactive((value) => value + 1), 1000);
  const resetTimer = React.useCallback(() => {
    
    setTimeInactive(0);
  }, [setTimeInactive]);


  React.useEffect(() => {
    window.addEventListener('load', resetTimer, true);
    ACTIVITY_EVENTS.forEach((event) =>
      window.addEventListener(event, resetTimer, true)
    );
    window.addEventListener(
      'message',
      (event) => {
        if (event.origin !== 'https://gpu.radiantlabs.co') return;
        
        resetTimer();
      },
      false
    );
    window.addEventListener('beforeunload', function (e) {
      sessionEndCallback();
    });
   
  }, [resetTimer, sessionEndCallback]);

  React.useEffect(() => {
    sessionStartCallback();
  }, [sessionStartCallback]);

  React.useEffect(() => {
    console.log(timeInactive, activeTimeout)
    if (timeInactive > activeTimeout && !snackbarKey) {
      console.log('Inside use effect')
      const snackbarKey = enqueueSnackbar('Your session is about to expire.', {
        preventDuplicate: true,
        key: 'session-expiration',
        persist: true,
        action: (key) => (
          <ExtendSessionButton
            onClick={() => {
              setSnackbarKey('');
              setTimeInactive(0);
              closeSnackbar(key);
            }}
            onTimerUp={sessionEndCallback}
          />
        ),
      });
      setSnackbarKey(snackbarKey);
    }
  }, [
    closeSnackbar,
    enqueueSnackbar,
    snackbarKey,
    sessionEndCallback,
    activeTimeout,
    timeInactive,
  ]);

  return null;
};

interface ExtendSessionButtonProps {
  onClick: () => void;
  onTimerUp?: () => void;
}

const ExtendSessionButton = ({
  onClick,
  onTimerUp,
}: ExtendSessionButtonProps) => {
  const [progress, setProgress] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(true);

  useInterval(() => setProgress((value) => value + 1), 1000, runTimer);

  React.useEffect(() => {
    if (progress >= 60 * 5) {
      onTimerUp?.();
      setRunTimer(false);
    }
  }, [progress, onTimerUp]);

  return (
    <Button
      variant="contained"
      onClick={onClick}
      startIcon={
        <CircularProgress size={20} variant="determinate" value={progress} />
      }
    >
      Extend my session
    </Button>
  );
};

const useInterval = (callback: () => void, delay: number, runTimer = true) => {
  const savedCallback = React.useRef(() => {
    return;
  });

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (delay !== null && runTimer) {
      const tick = () => {
        savedCallback.current();
      };
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return;
  }, [delay, runTimer]);
};
